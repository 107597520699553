import { createAsyncThunk } from '@reduxjs/toolkit'
import { toast } from 'sonner'
export const getLanguagesList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getLanguages', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/languages`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})
export const getCuratedLanguageList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getCuratedLanguageList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/languages/curated`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})
export const getProductFamilyList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getProductFamilyList', async (_, thunkAPI) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/product_families/product_family_search`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getCountryList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getCountryList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/countries`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getUnits = createAsyncThunk<any, void, { rejectValue: object }>(
  'getUnits',
  async (_, thunkAPI) => {
    try {
      const response = await fetch(`${process.env.API_URL}/units`, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      })
      const responseData = await response.json()
      return responseData
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error })
    }
  }
)

export const getFunctionList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getFunctionList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/departments`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getMembersList = createAsyncThunk<
  number,
  any,
  { rejectValue: object }
>('getMembersList', async (companyId: string, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `${process.env.API_URL}/member/users?company_id=${companyId}`,
      {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      }
    )
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return rejectWithValue({ error: error })
  }
})

export const getProductTypeList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getProductTypeList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/product_types`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getActivityList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getActivityList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/activities`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getBusinessSectorList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getBusinessSectorList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/industries`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()

    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getCertificationsList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getCertificationsList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/certifications`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export const getCriteriaList = createAsyncThunk<
  any,
  void,
  { rejectValue: object }
>('getCriteriaList', async (_, thunkAPI) => {
  try {
    const response = await fetch(`${process.env.API_URL}/news/criteria`, {
      method: 'GET',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
    })
    const responseData = await response.json()
    return responseData
  } catch (error) {
    return thunkAPI.rejectWithValue({ error: error })
  }
})

export async function fetchUrlAndConvertToFiles(urls: string[] | string) {
  try {
    let urlsArray: string[]
    if (typeof urls === 'string') {
      urlsArray = [urls]
    } else {
      urlsArray = urls
    }

    const files = await Promise.all(
      urlsArray.map(async (url) => {
        const response = await fetch(url, {
          mode: 'no-cors',
          credentials: 'same-origin',
          method: 'GET',
        })
        const blobData = await response.blob()
        const filename = url.substring(url.lastIndexOf('/') + 1)
        return new File([blobData], filename, { type: blobData.type })
      })
    )
    return files
  } catch (error) {
    console.error('Error fetching blobs and converting to files:', error)
    toast.error('Error fetching images')
    return []
  }
}

export const convertedData = async (data: { id: number; name: string }[]) => {
  return data.map((item: { id: number; name: string }) => ({
    name: item?.name,
    value: item?.name,
  }))
}

export const generateValueLabelFormate = (data: any) => {
  return (
    data?.map((item: any) => ({
      label: item?.name,
      value: item?.id,
    })) ?? []
  )
}

export const generateSingleValueLabelFormate = (data: any) => {
  return [
    {
      label: data?.name,
      value: data?.id,
    },
  ]
}

export const formatReactSelectOptions = (data: {
  id: number
  name: string
}) => {
  return { id: data?.id, value: data?.name, label: data?.name }
}

export function extractValues(data: any) {
  return data?.map((item: any) => item.value?.toString()) ?? []
}

export function formatNumber(input: number): string {
  const inputValue = input?.toString()
  if (!/^\d+$/.test(inputValue)) {
    return inputValue
  }

  const chars = inputValue.split('')

  let formatted = ''
  for (let i = 0; i < chars.length; i++) {
    formatted += chars[i]
    if ((chars.length - i - 1) % 3 === 0 && i !== chars.length - 1) {
      formatted += ','
    }
  }

  return formatted
}
